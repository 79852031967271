export const menuSuper = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        // badge: {
        //     variant: "success",
        //     text: "menuitems.dashboard.badge"
        // },
        link: '/'
    },
    {
        id: 31,
        label: 'Admin',
        icon: 'ri-user-6-line',
        subItems: [
            {
                id: 41,
                label: 'Register admin',
                link: '/admin/register'
            },
            {
                id: 51,
                label: 'View admins',
                link: '/admins'
            },

        ]
    },
    /* {
        id: 6,
        label: 'Site',
        icon: 'ri-map-pin-range-line',
        subItems: [
            {
                id: 7,
                label: 'Create Site',
                link: '/site/create-site'
            },
            {
                id: 8,
                label: 'View Site',
                link: '/site/',
                subItems:[
                    {
                        id:23 ,
                        label: 'All sites',
                        link: '/sites'
                    },
                    {
                        id: 9,
                        label: 'EID',
                        link: '/sites/eid'
                    },
                    {
                        id: 10,
                        label: 'VL',
                        link: '/sites/vl'
                    },
                ],
            },
        ]
    }, */
    /* {
        id: 11,
        label: 'PT Rounds',
        icon: 'ri-route-line',
        subItems: [
            {
                id:12,
                label:'EID PT Rounds',
                subItems: [
                    {
                        id:25,
                        label:'Create EID PT Round',
                        link:'/ptround/create-eid-ptround',
                    },
                    {
                        id:26,
                        label:'View  EID PT Rounds',
                        link: '/ptround/eid-ptrounds'
                    },
                ],
            },
            {
                id:27,
                label:'VL PT Rounds',
                subItems: [
                    {
                        id:28,
                        label:'Create VL PT Round',
                        link:'/ptround/create-vl-ptround',
                    },
                    {
                        id:29,
                        label:'View  VL PT Rounds',
                        link: '/ptround/vl-ptrounds'
                    },
                ],
            },

        ],
    }, */
    /* {
        id: 14,
        label: 'Transport System',
        icon: 'ri-bike-line',
        subItems: [
            {
                id:15,
                label:'Create a Transport System',
                link: '/biker/create-biker'
            },
            {
                id:16,
                label:'View Transport System',
                link: '/biker/bikers'
            },
        ],
    }, */
    /* {
        id: 17,
        label: 'Database',
        icon: 'ri-book-read-line',
        subItems: [
            {
                id:15,
                label:'Training',
                subItems: [
                    {
                        id:17,
                        label:'Create trainee',
                        link: '/training/create-trainee',
                    },
                    {
                        id:18,
                        label:'View trainees',
                        link: '/training/view-trainees',
                    },
                    {
                        id:18,
                        label:'Refresher trainee',
                        link: '/training/refresher-trainee',
                    },

                ],
            },
            // {
            //     id:16,
            //     label:'Training',
            //     subItems: [
            //         {
            //             id:19,
            //             label:'Create a training',
            //             link: '/training/create-training',
            //         },
            //         {
            //             id:24,
            //             label:'View trainings',
            //             link: '/training/view-trainings',
            //         }
            //
            //     ],
            // },
            {
                id:20,
                label: 'Certifications',
                subItems: [
                    {
                        id:21,
                        label:'Certification Registration',
                        link: '/certification/registration',
                    },
                    {
                        id:21,
                        label:'Add Cerification results',
                        link: '/certification/add-certification-results',
                    },

                    // {
                    //     id:21,
                    //     label:'Passed',
                    //     link: '/training/view-all'
                    // },
                    {
                        id:22,
                        label:'Successful certification',
                        link: '/certification/view-successful',
                    },{
                        id:23,
                        label:'Failed',
                        link: '/certification/view-failed',
                    }

                ]
            }
            // {
            //     id:16,
            //     label:'View Biker',
            //     link: '/biker/bikers'
            // },
        ],
    }, */
    {
        id:24,
        label: 'Contries',
        link:'/countries',
        icon: 'ri-earth-line'
    }

]